import React, { useState } from "react";
import { Link } from "react-router-dom";

const courseContent = [
    {
      id: "headingThree",
      title: "Pharmaceutical Sciences",
      duration: "5 lectures . 80 min",
      lectures: [
        {
          title: "Introduction to Pharmaceutical Chemistry",
          duration: "Duration: 1Hrs 30Mins",
        },
        {
          title: "Pharmacology Fundamentals",
          duration: "Duration: 2Hrs 30Mins",
        },
        {
          title: "Principles of Pharmaceutics",
          duration: "Duration: 1Hrs 20Mins",
        },
        {
          title: "Drug Formulation and Manufacturing",
          duration: "Duration: 2Hrs",
        },
      ],
    },
    {
      id: "headingOne",
      title: "Dispensing and Patient Care",
      duration: "5 lectures . 60 min",
      lectures: [
        {
          title: "Medication Dispensing Techniques",
          duration: "Duration: 1Hrs 30Mins",
        },
        {
          title: "Patient Counseling and Medication Adherence",
          duration: "Duration: 1Hrs 45Mins",
        },
        {
          title: "Pharmacy Ethics and Legal Principles",
          duration: "Duration: 1Hrs 15Mins",
        },
        {
          title: "Clinical Pharmacy Practice",
          duration: "Duration: 2Hrs",
        },
      ],
    },
    {
      id: "headingTwo",
      title: "Healthcare Support and Management",
      duration: "5 lectures . 70 min",
      lectures: [
        {
          title: "Role of Pharmacy in Healthcare Systems",
          duration: "Duration: 1Hrs 30Mins",
        },
        {
          title: "Pharmacy Operations and Workflow",
          duration: "Duration: 2Hrs",
        },
        {
          title: "Quality Assurance in Pharmacy Practice",
          duration: "Duration: 1Hrs 30Mins",
        },
        {
          title: "Pharmacy Inventory Management",
          duration: "Duration: 1Hrs 30Mins",
        },
      ],
    },
  ];
  

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>D Pharma</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src="assets/img/bg/dpharma.png" alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <div className="course-meta2 review style2 clearfix mb-30">
                    <ul className="left">
                      
                      
                    </ul>
                  </div>
                  <h3>Course Overview</h3>
                  <p>
                    <p>
                      The D.Pharma program is a diploma course emphasizing
                      pharmacy basics, medication dispensing, and healthcare
                      support. It spans two years and enables graduates to
                      pursue entry-level pharmacy positions.
                    </p>
                  </p>
                  <p>
                    The Diploma in Pharmacy (D.Pharma) program is a
                    comprehensive two-year course designed to equip students
                    with the fundamental knowledge and skills required for a
                    career in the pharmaceutical industry. This program delves
                    into various aspects of pharmacy, including pharmaceutical
                    sciences, medication dispensing, healthcare support, and
                    ethical pharmaceutical practices.
                  </p>
                  <h4>What You Will Learn</h4>
                  <p>
                    Pharmaceutical Sciences: Gain a foundational understanding
                    of pharmaceutical chemistry, pharmacology, pharmacognosy,
                    and pharmaceutics. Learn about the chemical properties of
                    drugs, their interactions with biological systems, and the
                    process of drug formulation and manufacturing. Medication
                    Dispensing: Develop practical skills in accurately
                    dispensing medications, including dosage calculations,
                    prescription interpretation, and labeling requirements.
                    Learn to ensure the safe and effective distribution of
                    pharmaceutical products to patients. Healthcare Support:
                    Explore the role of pharmacy in supporting healthcare
                    delivery systems. Understand how pharmacists contribute to
                    patient care through medication counseling, adherence
                    monitoring, and medication therapy management. Ethical and
                    Legal Principles: Familiarize yourself with the ethical and
                    legal guidelines governing pharmacy practice. Learn about
                    patient confidentiality, professional conduct, and
                    regulatory compliance to ensure the highest standards of
                    patient care and safety. Pharmacy Practice Management:
                    Acquire knowledge in pharmacy administration and management,
                    including inventory control, quality assurance, and workflow
                    optimization. Learn to effectively manage pharmacy
                    operations to enhance efficiency and patient satisfaction.
                    Patient Counseling and Education: Develop communication
                    skills to engage with patients and provide them with
                    essential information about their medications. Learn to
                    address patients' concerns, promote medication adherence,
                    and educate them about proper medication usage and potential
                    side effects.
                  </p>
                  <p>
                  Drug Information and Pharmacotherapy: Explore
                    various drug classes, their mechanisms of action,
                    therapeutic uses, and adverse effects. Gain insights into
                    evidence-based pharmacotherapy principles to make informed
                    decisions in patient care. Clinical Skills: Enhance clinical
                    skills through practical training in pharmacy settings, such
                    as community pharmacies, hospitals, and healthcare clinics.
                    Practice medication compounding, prescription processing,
                    and medication therapy monitoring under the supervision of
                    experienced pharmacists. Professional Development: Foster
                    professional skills such as critical thinking,
                    problem-solving, and interprofessional collaboration.
                    Prepare for a successful career in pharmacy by developing a
                    strong foundation in both clinical and non-clinical
                    competencies.
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Pharmaceutical Sciences
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Medication Dispensing
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Pharmacy Practice Management:
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Drug Information and Pharmacotherapy
                      </div>
                    </li>
                  </ul>
                  <h4>Study Options:</h4>
                  <table className="table table-bordered mb-40">
                    <thead>
                      <tr>
                        <th>Qualification</th>
                        <th>Marks </th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
            <td>10+2 Intermedialte</td></td>
                        <td>40% min</td>
                        
                      </tr>
                     
                    </tbody>
                  </table>
                  
                  <h3>Coures Content</h3>
                  <p>25 sections , 55 leactures</p>
                  <div
                    className="faq-wrap faq-wrap2 pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {courseContent.map((content, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={content.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {content.title}
                                <span>{content.duration}</span>
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">
                              <div className="leactures">
                                <ul>
                                  {content.lectures.map((lecture, i) => (
                                    <li key={i}>
                                      <a href="#">
                                        <div className="text">
                                          <i className="fal fa-edit" />{" "}
                                          {lecture.title}
                                        </div>
                                        <div className="time">
                                          <i className="fal fa-clock" />{" "}
                                          {lecture.duration}
                                        </div>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Course Features</h3>
                    <ul className="project-info clearfix">
                      
                      
                     
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong> <span>2 Years </span>
                      </li>
                      <li>
                        <span className="icon fal fa-user" />{" "}
                        <strong>Seat: </strong> <span>60 </span>
                      </li>
                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>Hindi/English</span>
                      </li>
                      <li>
                        <div className="slider-btn">
                          <Link
                            to="/contact"
                            className="btn ss-btn smoth-scroll"
                          >
                            Enroll <i className="fal fa-long-arrow-right" />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
