import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{ background: "#eff7ff" }}
      >
        <div className="animations-02">
          <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>

        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src="assets/img/features/about_img_02.png" alt="img" />
                <div className="about-text second-about">
                  <span>JNRC</span>
                  <p>Approved By</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> About
                  </h5>
                  <h2>A Few Words About the AGITE</h2>
                </div>
                <p className="txt-clr">
                  Dr. Avijeet Gautam Institute of Technical Education (AGITE) is
                  established by RIGHT PATH FOUNDATION and runs under the
                  umbrella of GAUTAM GROUP. Right Path Foundation is a
                  registered charitable trust dedicated to work for
                  professional/technical capacity building and skill development
                  education & training for youths to help in building their
                  career and generate or enhance jobs/earning opportunities.
                </p>
                <p>
                  The organization has done extraordinarily nicely in all
                  spheres of sports even as preserving the quality of schooling
                  that is being imparted to the students. The attempt put in by
                  using instructional and every curricular sports is without a
                  doubt commendable.
                </p>
                <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>01</span>
                            </div>{" "}
                            <div className="text">
                              <h3>Job Oriented Courses</h3>{" "}
                              <p>
                              Courses tailored for employment, aligning skills with industry requirements.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>02</span>
                            </div>{" "}
                            <div className="text">
                              <h3>Best Quality Teaching</h3>{" "}
                              <p>
                              Teaching excellence fostering deep understanding and lifelong learning skills.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="slider-btn mt-20">
                  <Link to="/about" className="btn ss-btn smoth-scroll">
                    Read More <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
