import React from "react";
import { Link } from "react-router-dom";

function Admission() {
  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{
          backgroundImage: "url(assets/img/bg/admission_bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src="assets/img/bg/agitclg.png" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h2>College Profile</h2>
                </div>
                <p className="txt-clr">
                  Dr. Avijeet Gautam Institute of Technical Education has been
                  established by Right Path Foundation in 2021-22. It is one of
                  the units of the trust running a no. of institutions of
                  professional education & training under the banner of Gautam
                  Group. The Group is dedicated to provide international quality
                  professional teaching & learning opportunities to youths
                  coming particularly from rural and semi-urban background. The
                  Institute is approved/recognized by the Government of
                  Jharkhand and Jharkhand Nurses Registration Council (JNRC),
                  Patna. It is affiliated with Vinoba Bhave University,
                  Hazaribagh (Jharkhand) for the Degree course (B.Sc. Nursing),
                  and with JNRC for the diploma courses (GNM) and B.harm,
                  D.Pharm.
                </p>
                <p className="txt-clr">
                  The Institute is located in Barhi campus of the Group, on NH2
                  (GT Road) at Barhi (Hazaribag). It is about 37 kms. from
                  Hazaribagh, an important district/town of Jharkhand.
                </p>
                <div className="slider-btn mt-20">
                  <Link to="/about" className="btn ss-btn smoth-scroll">
                    Read More <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Admission;
