import React from "react";
import Slider from "react-slick";

function Testimonial() {
  let settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="testimonial-area pt-120 pb-115 p-relative fix">
        <div className="animations-01">
          <img src="assets/img/bg/an-img-03.png" alt="an-img-01" />
        </div>
        <div className="animations-02">
          <img src="assets/img/bg/an-img-04.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Testimonial
                </h5>

                <h2>What Our Clients Says</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Slider
                className="testimonial-active wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
                {...settings}
              >
                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="img" />
                  </div>
                  <p>
                    "Choosing DR. AVIJEET GAUTAM INSTITUTE OF TECHNICAL EDUCATION (AGITE) for my pharmacy education was one of the best decisions I've made. The curriculum is comprehensive, the facilities are state-of-the-art, and the opportunities for practical experience are abundant. I feel confident and well-equipped to enter the pharmacy profession thanks to my time at this esteemed institution."
                  </p>
                  <div className="testi-author">
                    <img
                      src="assets/img/testimonial/testi_avatar.png"
                      alt="img"
                    />
                  </div>
                  <div className="ta-info">
                    <h6>Priya Patel</h6>

                    <span>Small Business Owner</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="img" />
                  </div>
                  <p>
                    "Studying at DR. AVIJEET GAUTAM INSTITUTE OF TECHNICAL EDUCATION (AGITE) has been an enriching experience. The faculty's dedication to excellence and hands-on approach to teaching have prepared me well for a successful career in pharmacy. I'm grateful for the support and guidance I've received throughout my journey here."
                  </p>
                  <div className="testi-author">
                    <img
                      src="assets/img/testimonial/testi_avatar_02.png"
                      alt="img"
                    />
                  </div>
                  <div className="ta-info">
                    <h6>Rahul Gupta</h6>
                    <span>Doctor</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="img" />
                  </div>
                  <p>
                    "As a recent graduate ofDR. AVIJEET GAUTAM INSTITUTE OF TECHNICAL EDUCATION (AGITE), I can confidently say that my experience here has prepared me to excel in the ever-evolving field of pharmacy. The rigorous coursework, hands-on training, and mentorship from experienced faculty have equipped me with the skills and knowledge needed to make a meaningful impact in patient care."
                  </p>
                  <div className="testi-author">
                    <img
                      src="assets/img/testimonial/testi_avatar_03.png"
                      alt="img"
                    />
                  </div>
                  <div className="ta-info">
                    <h6>Deepika Sharma</h6>
                    <span>Marketing Manager</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="img" />
                  </div>
                  <p>
                    "Enrolling atDR. AVIJEET GAUTAM INSTITUTE OF TECHNICAL EDUCATION (AGITE) was the first step towards fulfilling my dream of becoming a pharmacist. The dedicated faculty, cutting-edge research facilities, and immersive clinical experiences have not only prepared me for a successful career but have also instilled in me a lifelong passion for advancing healthcare through pharmacy.
                  </p>
                  <div className="testi-author">
                    <img
                      src="assets/img/testimonial/testi_avatar.png"
                      alt="img"
                    />
                  </div>
                  <div className="ta-info">
                    <h6>Deepika Sharma </h6>
                    <span>Freelance Photographer</span>
                  </div>
                </div>

                <div className="single-testimonial text-center">
                  <div className="qt-img">
                    <img src="assets/img/testimonial/qt-icon.png" alt="img" />
                  </div>
                  <p>
                    "I highly recommend this pharmacy college to anyone passionate about pursuing a career in pharmaceutical sciences. The comprehensive curriculum, state-of-the-art facilities, and supportive learning environment have equipped me with the knowledge and skills needed to succeed in this dynamic field.!"
                  </p>
                  <div className="testi-author">
                    <img
                      src="assets/img/testimonial/testi_avatar_02.png"
                      alt="img"
                    />
                  </div>
                  <div className="ta-info">
                    <h6>Rock Dloder</h6>
                    <span>Small Business Owner</span>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
