import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const faqData = [
    {
        question: 'What are the prerequisites for admission into the pharmacy program?',
        answer: 'To be admitted into our pharmacy program, students typically need to have completed certain prerequisite courses in subjects such as chemistry, biology, physics, and mathematics. Additionally, we often require satisfactory scores on standardized tests such as the PCAT (Pharmacy College Admission Test). Previous coursework or experience in healthcare-related fields can also strengthen an applicant profile.',
    },
    {
        question: 'Could you elaborate on the curriculum structure for the pharmacy degree?',
        answer: 'Our pharmacy degree program is designed to provide students with a comprehensive understanding of pharmaceutical sciences, patient care, and healthcare management. The curriculum includes courses in pharmacology, medicinal chemistry, pharmaceutics, pharmacotherapy, and pharmacy law. Students also engage in experiential learning through internships, clinical rotations, and research projects.',
    },
    {
        question: 'Can you tell me about the faculty members and their areas of expertise within the pharmacy department?',
        answer: 'Our faculty members bring a wealth of experience and expertise to the pharmacy department. They hold advanced degrees in pharmaceutical sciences, pharmacy practice, and related fields. Their areas of expertise include clinical pharmacy, drug development, pharmacotherapy, pharmacokinetics, and pharmaceutical education. Many faculty members are actively involved in research and professional organizations.',
    },
    {
        question: 'How does the college support students in gaining practical experience, such as internships or placements?',
        answer: 'We offer various opportunities for students to gain practical experience in pharmacy through internships, clinical placements, and externship programs. Our faculty members and career services team work closely with students to help them secure these opportunities, which provide valuable hands-on experience in diverse healthcare settings.',
    },
    {
        question: 'How does the college stay updated with the latest advancements and trends in the pharmaceutical industry?',
        answer: 'Our college prioritizes staying abreast of the latest advancements and trends in the pharmaceutical industry. We regularly update our curriculum to reflect current best practices and emerging technologies. Faculty members engage in ongoing professional development activities, attend conferences, and collaborate with industry partners to ensure that our students receive a relevant and cutting-edge education.',
    },
];


function First() {

    const [video, setVideo] = useState();

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="event event03 pt-150 pb-120 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: "url(assets/img/bg/video-img3.png)" }} >
                                <div className="s-video-content text-center">
                                    <h6>
                                        <Link to="#" className="popup-video mb-50" onClick={() => setVideo(true)}>
                                            <img src="assets/img/bg/play-button.png" alt="circle_right" />
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="accordion" id="accordionExample">
                                    {faqData.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={item.id}>
                                                <h2 className="mb-0">
                                                    <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`}
                                                        type="button" data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse${index}`}
                                                        onClick={() => handleToggle(index)} >
                                                        {item.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapse${index}`}
                                                className={`collapse${activeIndex === index ? ' show' : ''}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="card-body">{item.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style={{ overflow: "hidden" }}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)} >×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default First