import React, { useState } from "react";
import { Link } from "react-router-dom";

const faqItems = [
  {
    id: "headingThree",
    question: "01 What is Bharma?",
    answer:
      "Bharma is a revolutionary platform that integrates cutting-edge technology with ancient wisdom to provide holistic solutions for personal and societal well-being. It combines principles from various disciplines such as mindfulness, psychology, and neuroscience to empower individuals and communities.",
  },
  {
    id: "headingOne",
    question: "02 How can Bharma benefit me?",
    answer:
      "Bharma offers a range of practices and tools tailored to address modern challenges such as stress, anxiety, and burnout. By incorporating Bharma into your daily routine, you can enhance your mental clarity, emotional resilience, and overall quality of life.",
  },
  {
    id: "headingTwo",
    question: "03 Is Bharma suitable for everyone?",
    answer:
      "Yes, Bharma is designed to be inclusive and accessible to people from all walks of life. Whether you’re a busy professional, a student, or a retiree, Bharma provides resources and support to help you navigate life’s ups and downs with grace and ease. It offers customizable programs and flexible scheduling options to accommodate diverse needs and preferences.",
  },
];

function First() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-9 col-md-9 col-sm-12">
                <h2>B Pharma</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src="assets/img/bg/bpharma.png" alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <div className="course-meta2 review style2 clearfix mb-30">
                    <ul className="left">
                      <li>
                        <div className="author">
                         
                         
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                  <h3>Course Overview</h3>
                  <p>
                    The Bachelor of Pharmacy (B Pharma) program is designed to
                    provide students with a comprehensive understanding of
                    pharmaceutical sciences, preparing them for a rewarding
                    career in the field of pharmacy. Throughout the duration of
                    the program, students will delve into various subjects
                    ranging from pharmaceutical chemistry and pharmacology to
                    pharmaceutics and pharmacy practice.
                  </p>
                  <p>
                    The world of search engine optimization is complex and
                    ever-changing, but you can easily understand the basics, and
                    even a small amount of SEO knowledge can make a big
                    difference. Free SEO education is also widely available on
                    the web, including in guides like this! (Woohoo!) This guide
                    is designed to describe all major aspects of SEO, from
                    finding the terms and phrases (keywords) that can generate
                    qualified traffic to your website, to making your site
                    friendly to search engines, to building links and marketing
                    the unique value of your site.Etiam pharetra erat sed
                    fermentum feugiat velit mauris egestas quam ut erat justo.
                  </p>
                  <h4>What You Will Learn</h4>
                  <p>
                    The program focuses on equipping students with
                    industry-relevant skills such as drug regulatory affairs,
                    pharmacovigilance, and Good Manufacturing Practices (GMP)
                    compliance, ensuring they are well-prepared to meet the
                    demands of the pharmaceutical sector. Interdisciplinary
                    Learning: B Pharma encourages interdisciplinary learning by
                    integrating elements of biology, chemistry, and healthcare,
                    providing students with a holistic understanding of
                    pharmaceutical sciences.
                  </p>
                  <p>
                    Professional Development: Throughout the course, emphasis is
                    placed on professional development, fostering critical
                    thinking, ethical decision-making, and effective
                    communication skills essential for success in the
                    pharmaceutical profession. Career Opportunities:
                  </p>
                  <ul className="pr-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Pharmacist




                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Pharmaceutical Scientist
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Clinical Research Associate
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check" />
                      </div>
                      <div className="text">
                      Pharmaceutical Sales Representative
                      </div>
                    </li>
                  </ul>
                 
                  <table className="table table-bordered mb-40">
                    <thead>
                      <tr>
                        <th>Qualification</th>
                        <th>Marks </th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
            <td>10+2 Intermedialte</td></td>
                        <td>40% min</td>
                        
                      </tr>
                     
                    </tbody>
                  </table>
                  <h3>Frequently Asked Question</h3>
                 
                  <div
                    className="faq-wrap pt-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="accordion" id="accordionExample">
                      {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={item.id}>
                            <h2 className="mb-0">
                              <button
                                className={`faq-btn${
                                  activeIndex === index ? "" : " collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse${
                              activeIndex === index ? " show" : ""
                            }`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <aside className="sidebar-widget info-column">
                  <div className="inner-column3">
                    <h3>Course Features</h3>
                    <ul className="project-info clearfix">
                      
                      
                      <li>
                        <span className="icon fal fa-book" />{" "}
                        <strong>Lectures:</strong> <span>Offline</span>
                      </li>
                      <li>
                        <span className="icon fal fa-clock" />{" "}
                        <strong>Duration: </strong> <span>3year Including 6 Month Internship</span>
                      </li>
                      <li>
                        <span className="icon fal fa-user" />{" "}
                        <strong>Enrolled: </strong> <span>100 students</span>
                      </li>
                      <li>
                        <span className="icon fal fa-globe" />{" "}
                        <strong>Language: </strong> <span>Hindi/English</span>
                      </li>
                      <li>
                        <div className="slider-btn">
                          <Link
                            to="/contact"
                            className="btn ss-btn smoth-scroll"
                          >
                            Enroll <i className="fal fa-long-arrow-right" />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
