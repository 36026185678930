import React from 'react';
import { Link } from 'react-router-dom';

function First() {
  const courses = [
    {
      imgSrc: 'assets/img/bg/bpharma.png',
      category: 'B.Pharma',
      title: 'B.Pharma',
      description:
        'B.Pharm: 4-year undergraduate program covering pharmaceutical sciences, preparing graduates for diverse careers in pharmacy, healthcare, and research.',
    },
    {
      imgSrc: 'assets/img/bg/dpharma.png',
      category: 'D.Pharma',
      title: 'D.Pharma',
      description:
        'D.Pharm: Diploma program emphasizing pharmacy basics, medication dispensing, and healthcare support, spanning two years, enabling entry-level pharmacy positions.',
    },
    
  ];

  return (
    <>
      <section
        className="shop-area pt-120 pb-120 p-relative "
        data-animation="fadeInUp animated"
        data-delay=".2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <h6 className="mt-20 mb-50">Showing 1–9 of 10 results</h6>
            </div>
            <div className="col-lg-6 col-sm-6 text-right">
              <select name="orderby" className="orderby" aria-label="Shop order">
                <option value="menu_order">Default sorting</option>
                <option value="popularity">Sort by popularity</option>
                <option value="rating">Sort by average rating</option>
                <option value="date">Sort by latest</option>
                <option value="price">Sort by price: low to high</option>
                <option value="price-desc">Sort by price: high to low</option>
              </select>
            </div>
          </div>
          <div className="row align-items-center">
            {courses.map((course, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                    <Link to={index === 1 ? "/single-courses-2" : "/single-courses"}>
                      <img src={course.imgSrc} alt="course-img" />
                    </Link>
                  </div>
                  <div className="courses-content">
                    <div className="cat">
                      <i className="fal fa-graduation-cap" /> {course.category}
                    </div>
                    <h3>
                      <Link to={index === 1 ? "/single-courses-2" : "/single-courses"}>{course.title}</Link>
                    </h3>
                    <p>{course.description}</p>
                    <Link to={index === 1 ? "/single-courses-2" : "/single-courses"} className="readmore">
                      Read More <i className="fal fa-long-arrow-right" />
                    </Link>
                  </div>
                  <div className="icon">
                    <img src="assets/img/icon/cou-icon.png" alt="icon" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
